import React, { useContext } from "react";

import "./MainSection.css";

import Container from "../../components/ui/Container";
import bgBottomLeft from "../../assets/bottom-left.png";
import bgBottomRight from "../../assets/bottom-right.png";
import bg from "../../assets/bg.png";
import profile from "../../assets/profile.png";
const MainSection = ({ title = false }) => {
    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin relative overflow-hidden">
                {/* <div
                    className="inset-0 w-full h-full z-0 absolute opacity-0"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        // backgroundRepeat: "repeat-y",
                    }}
                ></div> */}
                {/* <img
                    className="bottom-0 left-0 max-w-md md:max-w-3xl w-full z-0 absolute"
                    src={bgBottomLeft}
                    alt="bg-bottom-left"
                />
                <img
                    className="bottom-0 right-0 max-w-xl w-full z-0 hidden md:block md:absolute"
                    src={bgBottomRight}
                    alt="bg-bottom-left"
                /> */}
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full space-y-20 md:space-y-0">
                        <div className=" md:basis-1/2 basis-full h-full flex items-center md:justify-start justify-center">
                            <div className="flex-col space-y-6 md:space-y-5 text-center md:text-right text-violet-700 dark:text-violet-500 smooth">
                                {/* <h2 className="font-h1 font-w-bold">منصة</h2> */}
                                <h1 className="font-bigmax font-w-bold before-box relative">
                                    <span className="relative z-10">
                                        <span>
                                            د /{" "}
                                            <span className="bg-box-purple relative">
                                                <span className="relative z-10">ميا</span>
                                            </span>
                                            ر <span className="">هشام</span>
                                        </span>
                                    </span>
                                </h1>
                                <div className="w-40 h-1 mx-auto md:mx-0 rounded-md bg-violet-500 opacity-50"></div>
                                <div className="flex-col">
                                    <h2 className="font-h1">
                                        منصة متخصصة{" "}
                                        <span className="text-yellow-500">في تدريس</span>{" "}
                                        <span className="font-w-bold">
                                            <span className="bg-box-purple relative">
                                                <span className="relative z-10">مادة الأحياء</span>
                                            </span>
                                        </span>{" "}
                                    </h2>
                                    <div className="font-h1">للثانوية العامة</div>
                                </div>
                                {title ? <div className="font-h2">{title}</div> : ""}
                                {/* <div className="py-16"></div> */}
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md">
                                <img src={profile} alt={"profile"} className="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
