import React from "react";

import { ScrollingProvider } from "../context/ScrollingContext";

import MainSection from "../sections/home/MainSection";
import AboutSection from "../sections/home/AboutSection";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import YearsSection from "../sections/home/YearsSection";

const Home = () => {
    return (
        <>
            <ScrollingProvider>
                <MainSection />
                <AboutSection />
            </ScrollingProvider>

            {/* <CoursesSection /> */}
            <YearsSection />
        </>
    );
};

export default Home;
