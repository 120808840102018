import React from "react";

// import logoTextDark from "../assets/navbar/logodark.png";
// import logoTextLight from "../assets/navbar/logolight.png";
import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import CenterIcon from "../components/ui/CenterIcon";

export const navLogoComponent = (darkmode, isNavbarHidden) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        {/* <img
            className="h-10 sm:h-14 w-auto md:mr-0 mr-6"
            src={darkmode ? logoTextDark : logoTextLight}
            alt="Workflow"
        /> */}
        <div className={`font-fs font-w-bold font-h1 space-x-2 text-center ${""}`}>
            <span className={` ${"text-indigo-800 dark:text-indigo-500 dark smooth"}`}>
                <span className="bg-box-purple relative">
                    <span className="relative z-10">د/</span>
                </span>
                ميار
            </span>
            {/* <span className="md:inline block">in English</span> */}
        </div>
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    `${""} ${!isRTL ? "en" : ""} ${scrolling ? "bg-primary-container" : ""}`;
export const NavbarRegisterComponent = ({ isNavbarHidden }) => (
    <NavbarLink
        defualtHover="hover:bg-opacity-0 bg-opacity-100 hover:text-slate-900 hover:border-violet-500group"
        to="/register"
        className={`clr-white border-2 group ${"bg-violet-600 border-violet-600 "}`}
    >
        <CenterIcon
            icon="carbon:intent-request-create"
            className={`font-h2 ${" text-violet-300 group-hover:text-violet-500 smooth"}`}
        />
        <span
            className={`flex-center-both space-x-1 space-x-reverse ${"clr-white smooth"} group-hover:text-violet-500`}
        >
            <span>انشئ</span>
            <span className={`font-bold ${" text-violet-300 group-hover:text-violet-500 smooth"}`}>
                حسابك !
            </span>
        </span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL, isNavbarHidden }) => (
    <NavbarLink
        to="/login"
        className="border border-yellow-400 border-opacity-0 text-yellow-500"
        defualtHover="hover:dark:border-opacity-100  hover-shadow"
    >
        {/* <svg
            className="text-blue-500"
            width={25}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.8">
                <rect x="8.25" y="4" width="10" height="15" rx="1" fill="currentColor" />
            </g>
            <path
                d="M8.86658 7.5L10.9499 10L8.86658 7.5Z"
                stroke="var(--color-text-primary)"
                className="smooth"
            />
            <path
                d="M8.86658 12.5L10.9499 10L8.86658 12.5Z"
                stroke="var(--color-text-primary)"
                className="smooth"
            />
            <path d="M10.5 10L3 10" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M15.5 17H6.3" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M15.5 3L6.32626 3" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M6.25 17V13" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M6.25 7V3" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M15.6 17V3" stroke="var(--color-text-primary)" className="smooth" />
        </svg> */}
        <CenterIcon icon="icon-park-solid:enter-key" className="font-h2 text-yellow-500" />
        <span
            className={`flex-center-both space-x-1 space-x-reverse ${
                !isRTL ? "flex-row-reverse" : ""
            } ${"clr-text-primary smooth"}`}
        >
            <span>تسجيل</span>
            <span
                className={`${
                    isNavbarHidden
                        ? " text-yellow-400 smooth"
                        : " text-yellow-600 dark:text-yellow-400 smooth"
                }`}
            >
                الدخول
            </span>
        </span>
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        {/* <svg
            width={25}
            className="text-blue-500"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.8">
                <rect x="8.25" y="4" width="10" height="15" rx="1" fill="currentColor" />
            </g>
            <path
                d="M8.86658 7.5L10.9499 10L8.86658 7.5Z"
                stroke="var(--color-text-primary)"
                className="smooth"
            />
            <path
                d="M8.86658 12.5L10.9499 10L8.86658 12.5Z"
                stroke="var(--color-text-primary)"
                className="smooth"
            />
            <path d="M10.5 10L3 10" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M15.5 17H6.3" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M15.5 3L6.32626 3" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M6.25 17V13" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M6.25 7V3" stroke="var(--color-text-primary)" className="smooth" />
            <path d="M15.6 17V3" stroke="var(--color-text-primary)" className="smooth" />
        </svg> */}

        <CenterIcon icon="icon-park-solid:enter-key" className="font-h2 text-yellow-500" />
        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        {/* <svg
            width={25}
            className={"text-rose-500"}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.8">
                <path
                    d="M3.88889 11H2.5C2.03934 11 1.82377 10.4299 2.16919 10.1251L10.6692 2.62508C10.8582 2.45831 11.1418 2.45831 11.3308 2.62508L19.8308 10.1251C20.1762 10.4299 19.9607 11 19.5 11H18.1111V18C18.1111 18.2761 17.8873 18.5 17.6111 18.5H4.38889C4.11274 18.5 3.88889 18.2761 3.88889 18V11Z"
                    fill="currentColor"
                />
            </g>
            <path
                d="M0.999997 10H2.38889V17C2.38889 17.2761 2.61274 17.5 2.88889 17.5H16.1111C16.3873 17.5 16.6111 17.2761 16.6111 17V10H18C18.4607 10 18.6762 9.42986 18.3308 9.12508L9.83081 1.62508C9.6418 1.45831 9.3582 1.45831 9.16919 1.62508L0.669186 9.12508C0.323766 9.42986 0.539337 10 0.999997 10ZM2.88889 9H2.32238L9.5 2.66681L16.6776 9H16.1111C15.835 9 15.6111 9.22386 15.6111 9.5V16.5H3.38889V9.5C3.38889 9.22386 3.16503 9 2.88889 9Z"
                fill="var(--color-text-primary)"
                className="smooth"
            />
            <path
                d="M10.7083 11.5H8.20833C7.65604 11.5 7.20833 11.9477 7.20833 12.5V16.5C7.20833 17.0523 7.65604 17.5 8.20833 17.5H10.7083C11.2606 17.5 11.7083 17.0523 11.7083 16.5V12.5C11.7083 11.9477 11.2606 11.5 10.7083 11.5ZM8.20833 16.5V12.5H10.7083V16.5H8.20833Z"
                fill="var(--color-text-primary)"
                className="smooth"
            />
        </svg> */}
        <CenterIcon
            icon="carbon:intent-request-create"
            className={`font-h2 text-rose-400  smooth
            `}
        />
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">انشئ حسابك الآن !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";
